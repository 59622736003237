<template>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" v-if="this.timer > 0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div v-if="queue?.position !== 0">
                                <h3 class="text-black mb-2 font-w600">
                                    Olá, <b>{{ userAuth.first_name }}</b> 😊 <br>
                                </h3>
                                <hr>

                                <h4>
                                    Sua posição na fila: <br>
                                    <span class="h1 text-green pisca" style="font-size: 200%">
                                      {{ queue?.position == null ? '...' : queue?.position + '°' }}
                                  </span>
                                </h4>
                                <hr>

                                <p>
                                    O tempo estimado de espera é de <br>
                                    <span class="h3 text-info">
                                      {{ queue?.estimated_time }} minuto(s)
                                    </span>
                                </p>

                                <p>
                                    <b>Aguarde! <br> Você será atendido(a) em breve</b> 🤗
                                </p>
                            </div>
                            <div v-else>
                                <h2 class="text-black mb-2 font-w600">
                                    {{ userAuth.first_name }}, <br>
                                    É A SUA VEZ 🥳
                                </h2>
                                <hr>
                                <br>

                                <p>
                                  <span class="h3 text-green">
                                      <b>Clique no botão 'Entrar' <br> para iniciar o atendimento.</b>
                                    <br>
                                    <br>
                                    Ao entrar na sala, aguarde até que o profissional fale com você 🤗
                                  </span>
                                    <br>
                                </p>
                                <br>

                                <p>
                                    <b>Atenção, você tem<br>
                                        <span class="h3 text-info pisca">
                                            {{ timer }} segundos
                                        </span>
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="d-grid gap-2 text-center" :class="queue?.position === 0 ? 'd-md-block' : ''">
                            <button class="btn btn-secondary mt-1 me-2 ms-2" type="button" @click="confirmLeave()">
                                <i class="fa fa-arrow-circle-left"></i> Desistir
                            </button>
                            <button v-if="queue?.position === 0" class="btn btn-primary mt-1 me-2 ms-2"
                                    type="button" @click="confirmStartAttendance()">
                                <i class="fa fa-arrow-circle-right"></i> Entrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="card shadow rounded border-0 mb-3 pricing-rates business-rate">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div>
                                <h2 class="text-black mb-2 font-w600">
                                    TEMPO LIMITE DE ESPERA EXCEDIDO.
                                </h2>
                                <hr>
                                <br>

                                <p>
                                  <span class="h3 text-green">
                                      <b>{{ userAuth.first_name }}</b> A sua vez para ser atendido chegou e você não entrou na sala.
                                    <br>
                                    <br>
                                    Você pode entrar na fila novamente.
                                  </span>
                                    <br>
                                </p>
                                <br>

                                <button class="btn btn-primary"
                                        type="button" @click="reEntryInQueue()">
                                    <i class="fa fa-arrow-circle-right"></i> Entrar novamente na fila
                                </button>

                                <br>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="d-grid gap-2 text-center">
                            <button class="btn btn-secondary mt-1 me-2 ms-2" type="button" @click="confirmLeave()">
                                <i class="fa fa-arrow-circle-left"></i> Desistir
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Swal from "sweetalert2";

export default {
    name: "Queue",
    data() {
        return {
            timer: 90,
            queue: null,
            scheduleId: null,
            queueId: null,
        }
    },
    mounted() {
        this.requestPermissionNotifications();
    },
    created() {
        this.scheduleId = this.$route.params.scheduleId;
        this.queueId = this.$route.params.queueId;
        this.getQueue().then(() => {
            if (!['A', 'E'].includes(this.queue.status)) {
                this.$router.push(`/schedules`);
                return;
            }

            if (this.queue.status === 'A') {
                this.$router.push(`/schedules/${this.scheduleId}/attendance`);
                return;
            }

            this.wsConnect();
        });
    },
    computed: {
        userAuth() {
            return this.$store.state.auth.user;
        }
    },
    beforeUnmount() {
        this.timer = 0;
        Echo.leave(`queues.${this.queueId}`);
    },
    methods: {
        reEntryInQueue() {
            http.post(`/users/${this.userAuth.id_user}/schedules/${this.scheduleId}/queues/entry`).then((response) => {
                window.location.reload();
                return response;
            }).catch((e) => {
                let response = e.response?.data;
                Alert.error(response);
            });
        },
        confirmStartAttendance() {
            Swal.fire({
                title: 'Atenção',
                text: "Tem certeza que deseja ENTRAR na sala de atendimento?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#780f5a',
                cancelButtonColor: '#ccc',
                confirmButtonText: 'Sim, Entrar na sala!',
                cancelButtonText: "Não"
            }).then((result) => {
                if (result.isConfirmed) {
                    http.post(`/users/${this.userAuth.id_user}/schedules/${this.scheduleId}/queues/${this.queueId}/start`).then(() => {
                        this.$router.push(`/schedules/${this.scheduleId}/attendance`);
                    }).catch((e) => {
                        let response = e.response.data;
                        Alert.error(response);
                    });
                }
            });
        },
        confirmLeave() {
            Swal.fire({
                title: 'Atenção',
                text: "Tem certeza que deseja SAIR da fila de atendimento?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#780f5a',
                cancelButtonColor: '#ccc',
                confirmButtonText: 'Sim, Desistir!',
                cancelButtonText: "Não"
            }).then((result) => {
                if (result.isConfirmed) {
                    http.post(`/users/${this.userAuth.id_user}/schedules/${this.scheduleId}/queues/${this.queueId}/leave`).then((response) => {
                        this.$router.push(`/schedules`);
                        return response;
                    }).catch((e) => {
                        let response = e.response.data;
                        Swal.fire({
                            icon: 'error',
                            title: 'Falha ao entrar na fila',
                            text: response,
                        });
                    });
                }
            });
        },
        getQueue() {
            let url = `/users/${this.userAuth.id_user}/schedules/${this.scheduleId}/queues/${this.queueId}`
            return http.get(url).then((response) => {
                this.queue = response.data;

                if (this.queue.position === 0) {
                    this.callToAttendance();
                }

                return response;
            }).catch((e) => {
                Alert.error(e.response.data);
            });
        },
        startTimer() {
            let interval = window.setInterval(() => {
                if (this.timer <= 0) {
                    clearInterval(interval);
                    return;
                }

                this.timer--;
            }, 1000);
        },
        wsConnect() {
            Echo.private(`queues.${this.queueId}`)
                .listen('QueueUpdated', (e) => {
                    if (!['A', 'E'].includes(e.queueLocal.status)) {
                        this.$router.push(`/schedules`);
                        return;
                    }

                    this.queue = e.queueLocal;

                    if (e.queueLocal.position === 0) {
                        this.callToAttendance();
                    }
                });
        },
        callToAttendance() {
            this.alertPosition();
            this.startTimer();
            this.sendPushNotification();
        },
        async alertPosition() {
            let audio = new Audio('@/assets/mp3/queue-alert.wav');
            audio.play();

            if (this.timer <= 0) {
                return;
            }

            setTimeout(() => this.alertPosition(), 2000)
        },
        sendPushNotification() {
            try {
                let text = `${this.userAuth.first_name}, chegou sua vez de ser atendido.`;
                new Notification('Ubbimed', { body: text});
            } catch (e) {}
        },
        requestPermissionNotifications() {
            return Notification.requestPermission();
        },
    }
}
</script>

<style scoped>
.pisca {
    animation: anima 1200ms ease infinite;
}

@keyframes anima {
    to {
        opacity: 0.4;
    }
}

</style>