<script>
import CreditCardMP from './gateways/CreditCardMP.vue'
import {mask} from "vue-the-mask";
import http from "../../helpers/http";
import InputSelect from "../components/InputSelect.vue";
import Alert from "../../helpers/alert";
import Swal from "sweetalert2";

export default {
  name: "PaymentAppToSchedule",
  components: {
    InputSelect,
    CreditCardMP
  },
  directives: {
    mask,
  },
  data() {
    return {
      msgErrorSchedule: '',
      states: null,
      citiesFromState: null,
      userAddress: {
        user_address: null,
        user_addr_zipcode: null,
        user_addr_numer: null,
        user_addr_compl: null,
        user_neighborhood: null,
        id_city: null,
        id_state: null,
      },
      cpf: '',
      email: '',
      id_place: '',
      amount: '',
      tokenSession: '',
      creditCard: {
        issuer_id: '',
        payment_method_id: '',
        token: '',
        installments: '',
        device_id: '',
      },
    }
  },
  created() {
    let script = 'https://sdk.mercadopago.com/js/v2';
    let tag = document.head.querySelector(`[src="${script}"`);
    tag = document.createElement("script");
    tag.setAttribute("src", script);
    tag.setAttribute("type", 'text/javascript');
    document.head.appendChild(tag);

    let scriptSecurity = 'https://www.mercadopago.com/v2/security.js';
    let tagSecurity = document.head.querySelector(`[src="${scriptSecurity}"`);
    tagSecurity = document.createElement("script");
    tagSecurity.setAttribute("src", scriptSecurity);
    tagSecurity.setAttribute("type", 'text/javascript');
    tagSecurity.setAttribute("view", 'checkout');
    tagSecurity.setAttribute("output", 'deviceId');
    document.head.appendChild(tagSecurity);
  },
  async mounted() {
    this.getStates();

    this.cpf = this.$route.query.cpf;
    this.email = this.$route.query.email;
    this.id_place = this.$route.query.id_place;
    this.amount = this.$route.query.amount;
    this.tokenSession = this.$route.query.token_session;
    await this.authUsingToken();

    let response = await http.get('/card-administrators/MP/public-key', {
      params: {
        id_place: this.id_place,
      }
    });
    let publicKey = response.data;

    await new Promise(r => setTimeout(r, 1000));
    const mp = new MercadoPago(publicKey);

    const cardForm = mp.cardForm({
      locale: 'pt-BR',
      amount: this.amount,
      iframe: false,
      form: {
        id: "form-checkout",
        cardNumber: {
          id: "checkout__cardNumber",
          placeholder: "Número do cartão",
        },
        expirationDate: {
          id: "checkout__expirationDate",
          placeholder: "MM/YY",
        },
        securityCode: {
          id: "checkout__securityCode",
          placeholder: "Código de segurança",
        },
        cardholderName: {
          id: "checkout__cardholderName",
          placeholder: "Titular do cartão",
        },
        issuer: {
          id: "checkout__issuer",
          placeholder: "Banco emissor",
        },
        installments: {
          id: "checkout__installments",
          placeholder: "Parcelas",
        },
        identificationType: {
          id: "checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "checkout__identificationNumber",
          placeholder: "Número do documento",
        },
        cardholderEmail: {
          id: "checkout__cardholderEmail",
          placeholder: "E-mail",
        },
      },
      callbacks: {
        onFormUnmounted: error => {
          if (error) Alert.error(JSON.stringify(error));
          console.log('Form unmounted')
        },
        onIdentificationTypesReceived: (error, identificationTypes) => {
          if (error) Alert.error(JSON.stringify(error));
          console.log('Identification types available: ', identificationTypes)
        },
        onPaymentMethodsReceived: (error, paymentMethods) => {
          if (error) Alert.error(JSON.stringify(error));
          console.log('Payment Methods available: ', paymentMethods)
        },
        onIssuersReceived: (error, issuers) => {
          if (error) Alert.error(JSON.stringify(error));
          console.log('Issuers available: ', issuers)
        },
        onInstallmentsReceived: (error, installments) => {
          if (error) Alert.error(JSON.stringify(error));
          console.log('Installments available: ', installments)
        },
        onCardTokenReceived: (error, token) => {
          if (error) Alert.error('Verifique os dados do cartão e tente novamente');
          console.log('Token available: ', token)
        },
        onFormMounted: error => {
          if (error) Alert.error(JSON.stringify(error));
        },
        onSubmit: (event) => {
          event.preventDefault();
          const cardFormData = cardForm.getCardFormData();

          this.creditCard = {
            issuer_id: cardFormData.issuerId,
            payment_method_id: cardFormData.paymentMethodId,
            token: cardFormData.token,
            installments: cardFormData.installments,
            device_id: document.getElementById('deviceId')?.value,
          };

          let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modalAddress2'));
          modal.toggle();
        },
      },
    });
  },
  methods: {
    authUsingToken() {
      return this.$store.dispatch('auth/loginUsingToken', this.tokenSession)
        .catch(() => Alert.error('Falha ao fazer login.'));
    },
    scheduleAppointment() {
      this.msgErrorSchedule = '';
      if (!this.creditCard) {
        Alert.error('Falha ao processar os dos cartão de crédito - Verifique sua conexão e tente novamente.');

        return;
      }

      let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modalAddress2'));
      modal.toggle();

      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.postMessage(JSON.stringify({creditCard: this.creditCard, userAddress: this.userAddress}));
      } else {
        Alert.error('Falha ao processar os dos cartão de crédito - Entre em contato com a Ubbimed.');
      }
    },
    getStates() {
      http.get(`/states`).then((response) => {
        this.states = response.data;
      });
    },
    getCitiesFromState(stateId) {
      return http.get(`/states/${stateId}/cities`).then((response) => {
        this.citiesFromState = response.data;

        return response;
      });
    },
    getZipCode(zipCode) {
      http.get(`/zipcode/${zipCode}`).then((response) => {
        let data = response.data;
        this.userAddress.user_address = data.address;
        this.userAddress.user_neighborhood = data.neighborhood;
        let state = data.state;
        this.userAddress.id_state = state?.id_state;
        this.getCitiesFromState(state?.id_state);
        this.userAddress.id_city = data.city?.id_city;
      });
    },
  },
}
</script>

<template>
  <div class="m-4">
    <div class="alert alert-danger" role="alert" v-if="msgErrorSchedule">
      {{ msgErrorSchedule }}
    </div>

    <div class="bg-light p-4 text-center rounded-top"><h4 class="mb-0 card-title text-primary">Cartão de crédito</h4></div>

    <form id="form-checkout" method="post">
      <input type="hidden" id="deviceId">
      <select id="checkout__identificationType" style="display:none;"></select>
      <input type="text" id="checkout__identificationNumber" style="display:none;" :value="cpf"/>
      <input type="text" id="checkout__cardholderEmail" style="display:none;" :value="email"/>

      <div class="row">
        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label" for="checkout__cardholderName">Nome: <span
              class="text-danger">*</span></label>
            <input name="name" id="checkout__cardholderName" type="text"
                   class="form-control fw-bold" required="" style="height: 50px;"
                   placeholder="Ex. João S Farias">
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label" for="credit_card_number">
              Número do cartão: <span class="text-danger">*</span>
            </label>
            <input type="tel" autocomplete="off" id="checkout__cardNumber" style="height: 50px;"
                   class="form-control fw-bold" v-mask="'#### #### #### ####'"
                   required="" placeholder="0000 0000 0000 0000">
          </div>
        </div>
        <div class="col-md-12" style="display: none">
          <div class="mb-3">
            <label class="form-label" for="checkout__issuer">
              Emissor: <span class="text-danger">*</span>
            </label>
            <select type="text" id="checkout__issuer" style="height: 50px;"
                    class="form-control fw-bold">
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label" for="credit_card_number">
              Parcelas: <span class="text-danger">*</span>
            </label>
            <select id="checkout__installments" class="form-control fw-bold" style="height: 50px;">
              <option value="" disabled>Preencha os dados do cartão.</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="credit_card_data_valid">Data de expiração: <span
              class="text-danger">*</span></label>
            <input type="tel" autocomplete="off" id="checkout__expirationDate"
                   class="form-control fw-bold" style="height: 50px;"
                   required="" placeholder="MM/AAAA" v-mask="'##/####'">
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="credit_card_cvv">Código de segurança: <span
              class="text-danger">*</span></label>
            <input type="tel" autocomplete="off" id="checkout__securityCode"
                   class="form-control fw-bold" style="height: 50px;"
                   required="" placeholder="CVV" v-mask="'###'">
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-grid">
            <button class="btn btn-primary" style="height: 58px;" type="submit" id="checkout__submit">
              Agendar consulta
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal" id="modalAddress2" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form @submit.prevent="scheduleAppointment">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirme o seu endereço</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="address_cep" class="form-label required">
                    CEP <i class="text-danger">*</i>
                  </label>
                  <input type="text" class="form-control" id="address_cep"
                         @change="getZipCode($event.target.value)" required
                         v-model="userAddress.user_addr_zipcode">
                </div>
              </div>
              <div class="col-md-8">
                <div class="mb-3">
                  <label for="address" class="form-label required">
                    Endereço <i class="text-danger">*</i>
                  </label>
                  <input type="text" class="form-control" id="address" required
                         v-model="userAddress.user_address">
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="address_nro" class="form-label required">
                    Número <i class="text-danger">*</i>
                  </label>
                  <input type="text" class="form-control" id="address_nro" required
                         v-model="userAddress.user_addr_numer">
                </div>
              </div>
              <div class="col-md-8">
                <div class="mb-3">
                  <label for="address_neighbourhood" class="form-label required">
                    Bairro <i class="text-danger">*</i>
                  </label>
                  <input type="text" class="form-control" id="address_neighbourhood" required
                         v-model="userAddress.user_neighborhood">
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="address_complement" class="form-label">
                    Complemento
                  </label>
                  <input type="text" class="form-control" id="address_complement"
                         v-model="userAddress.user_addr_compl">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="address_stage" class="form-label">
                    Estado <i class="text-danger">*</i>
                  </label>
                  <InputSelect :required="true"
                               id="address_stage"
                               label="state_name"
                               @change="getCitiesFromState($event)"
                               value="id_state"
                               :options="states"
                               v-model="userAddress.id_state"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="address_city" class="form-label">
                    Cidade <i class="text-danger">*</i>
                  </label>
                  <InputSelect id="address_city" :required="true"
                               label="city_name"
                               value="id_city"
                               :options="citiesFromState"
                               v-model="userAddress.id_city"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-md-12">
              <div class="d-grid">
                <button type="submit" style="height: 58px" class="btn btn-primary">
                  Agendar consulta
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>