<template>
  <section class="bg-home mt-5 d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <div class="me-lg-5">
            <img src="../assets/images/login.svg" class="img-fluid d-block mx-auto" alt="Login">
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="card login-page bg-white shadow rounded border-0">
            <div class="card-body">
              <h4 class="card-title text-center">Acesse sua conta</h4>

              <div class="alert alert-danger" role="alert" v-if="messageError">
                {{ messageError }}
              </div>

              <form class="login-form mt-4" method="post" @submit.prevent="login">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label" for="user_email">CPF ou E-mail<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-user fea icon-sm icons">
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        <input type="text" class="form-control ps-5" style="text-transform: lowercase;"
                               :class="errors.user_email ? 'is-invalid' : ''" placeholder="Seu CPF ou e-mail"
                               name="email" required="" v-model="auth.username" id="user_email">
                        <div class="invalid-feedback d-block" v-if="errors.user_email">
                          {{ errors.user_email.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label" for="user_password">Senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-key fea icon-sm icons">
                          <path
                              d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                        </svg>

                        <input :type="showPassword ? 'text' : 'password'" class="form-control ps-5"
                               :class="errors.user_password ? 'is-invalid' : ''" id="user_password"
                               placeholder="Sua senha" required="" v-model="auth.password">
                        <div class="invalid-feedback d-block" v-if="errors.user_password">
                          {{ errors.user_password.join('\n') }}
                        </div>

                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="showPassword" v-model="showPassword">
                          <label class="form-check-label" style="cursor: pointer;" for="showPassword">Mostrar
                            senha</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 text-end">
                    <div class="mb-3">
                      <button type="button" class="btn btn-link" data-bs-toggle="modal"
                              data-bs-target="#ModalRecoverPassword">
                        Esqueceu a senha?
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-0">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary" :disabled="submitted">Acessar conta</button>
                    </div>
                  </div>

                  <div class="col-lg-12 mt-4 text-center">
                    <h6>Ou acesse com</h6>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <div class="d-grid">
                          <BtnLoginGoogle v-on:onSuccess="onLoginGoogle" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 text-center">
                    <p class="mb-0 mt-3">
                      <small class="text-dark me-2">Ainda não tem uma conta ?</small>
                      <router-link to="/register" class="text-dark fw-bold">Cadastre-se</router-link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <RecoverPasswordModal/>

  </section>
</template>

<script>
import BtnLoginGoogle from './components/BtnLoginGoogle'
import BtnLoginFacebook from './components/BtnLoginFacebook'
import http from "../helpers/http";
import {mask} from "vue-the-mask";
import RecoverPasswordModal from "./RecoverPasswordModal";

export default {
  name: "AuthLogin",
  directives: {
    mask,
  },
  components: {
    BtnLoginGoogle,
    BtnLoginFacebook,
    RecoverPasswordModal,
  },
  data() {
    return {
      auth: {
        username: '',
        password: '',
      },
      showPassword: false,
      submitted: false,
      errors: [],
      messageError: '',
      messageRecoverPassword: '',
      messageRecoverPasswordError: '',
    }
  },
  created() {
    if (this.$store.getters['auth/isAuth'])
      this.$router.push('/schedules');
  },
  methods: {
    onLoginFacebook(responseFacebook) {
      this.loginSocial('facebook', responseFacebook.accessToken);
    },
    onLoginGoogle(googleUser) {
      let accessToken = googleUser.credential;
      this.loginSocial('google', accessToken);
    },
    loginSocial(driver, accessToken) {
      this.submitted = true;
      this.errors = {};
      this.messageError = null;
      http.post(`/auth/social/${driver}`, {access_token: accessToken}).then((response) => {
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);
        this.$router.push('/schedules');
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
        } else if (response?.status === 401) {
          this.messageError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
    login() {
      this.submitted = true;
      this.errors = {};
      this.messageError = null;
      this.$store.dispatch('auth/login', this.auth).then(() => {
        this.$router.push('/schedules');
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
        } else if (response?.status === 401) {
          this.messageError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
  }
}
</script>

<style scoped>

</style>