<template>
  <div class="modal fade" id="modal-waiting-list" tabindex="-1" data-bs-backdrop="static"
       data-bs-keyboard="false" aria-labelledby="modal-waiting-list" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content rounded shadow border-0">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Solicitação de encaixe</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-8">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">Profissional</span>
                  </label>
                  <input type="text" class="form-control form-control-solid" disabled :value="profName">
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">Forma de pagamento</span>
                  </label>
                  <select class="form-control form-control-solid" v-model="form.id_paymeth" required>
                    <option value="" selected disabled>--Selecione--</option>
                    <option :value="paymentMethod.id_paymeth" v-for="paymentMethod in paymentMethods">
                      {{paymentMethod.paymeth_name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">CPF do paciente</span>
                  </label>
                  <input type="tel" class="form-control form-control-solid" maxlength="15" autofocus
                         v-mask="'###.###.###-##'" required
                         placeholder="Ex. 000.000.000/00" v-model="form.wl_cpf">
                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">Nome do paciente</span>
                  </label>
                  <input type="text" class="form-control form-control-solid" maxlength="225" required
                         placeholder="Ex. João da silva" v-model="form.wl_name">
                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">WhatsApp para contato</span>
                  </label>
                  <input type="tel" class="form-control form-control-solid"
                         v-mask="['(##) ####-####', '(##) #####-####']" required
                         placeholder="Ex. (99) 9 9999-9999" v-model="form.wl_phone">
                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">Data de nascimento</span>
                  </label>
                  <input type="tel" class="form-control form-control-solid"
                         v-mask="['##/##/####']" required
                         placeholder="DD/MM/YYYY" v-model="form.wl_dt_birth">
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                  <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span class="required">Observação</span>
                  </label>
                  <textarea class="form-control form-control-solid" v-model="form.wl_obs" maxlength="450"
                            placeholder="Escreva algo importante aqui."></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 text-end">
                <button class="btn btn-primary" type="submit">Solicitar encaixe</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Swal from "sweetalert2";
import Modal from "../../helpers/modal";
import {mask} from 'vue-the-mask';

export default {
  name: "WaitingListModal",
  props: ['professional', 'cityId', 'specId'],
  directives: {
    mask,
  },
  data() {
    return {
      profName: null,
      paymentMethods: [],
      form: {
        wl_name: '',
        wl_cpf: '',
        wl_phone: '',
        id_patient: '',
        id_user: '',
        id_insurance: '',
        id_paymeth: '',
        id_prof: '',
        id_spec: '',
        id_place: '',
        wl_obs: '',
        wl_dt_birth: '',
      }
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
    insurance() {
      return this.$store.state.toSchedule.schedule.insurance;
    },
  },
  mounted() {
    Modal.onShow('#modal-waiting-list', () => {
      this.resetForm();
      this.loadPaymentMethods();

      this.profName = this.professional.prof_name;
      this.form.id_insurance = this.insurance?.id_insurance;
      this.form.id_paymeth = '';
      this.form.id_prof = this.professional.id_prof;
      this.form.id_spec = this.specId;
      this.form.id_place = this.professional.id_place;
      this.form.id_user = this.userAuth?.id_user;
    });
  },
  methods: {
    loadPaymentMethods() {
      http.get('/schedule/payment-methods', {
        params: {
          id_city: this.cityId,
          id_spec: this.specId,
          id_prof: this.professional.id_prof,
        }
      }).then((response) => {
        this.paymentMethods = response.data;
      });
    },
    onSubmit() {
      const saveWaitingList = (tryPrivaceSchedule) => {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
        });
        Swal.showLoading();

        http.post(`/places/${this.form.id_place}/waiting-lists`, {...this.form, wl_accept_private_sched: tryPrivaceSchedule}).then(() => {
          this.resetForm()

          Modal.toggle('#modal-waiting-list');
          Swal.fire({
            title: 'Aeeeh!',
            text: "Paciente adicionado com sucesso.",
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Acessar minha conta',
            confirmButtonColor: '#780f5a',
          }).then(() => {
            this.$router.push('/schedules')
          });
        }).catch(e => {
          let message = e.response.data;
          if (e.response.data.message)
            message = e.response.data.message;

          Alert.error(message);
        });
      }

      Swal.fire({
        html:
          "<p>Caso prefira não esperar, a Ubbimed pode tentar agendar uma consulta particular nessa mesma especialidade com outro profissional.</p>" +
          "<h3>Podemos tentar para você?</h3>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#780f5a",
        confirmButtonText: "Sim, preciso com urgência",
        cancelButtonText: "Não, quero tentar um encaixe",
      }).then((result) => {
        saveWaitingList(result.isConfirmed);
      });
    },
    resetForm() {
      this.form = {
        wl_name: '',
        wl_cpf: '',
        wl_phone: '',
        id_patient: '',
        id_user: '',
        id_insurance: '',
        id_paymeth: '',
        id_prof: '',
        id_spec: '',
        id_place: '',
        wl_obs: '',
        wl_dt_birth: '',
      }
    },
  }
}
</script>

<style scoped>

</style>