<template>
  <div>
    <section class="bg-half-170 d-table w-100">
      <div class="container">
        <div class="row mt-5 align-items-center">
          <div class="col-lg-6 col-md-7">
            <div class="title-heading">
              <h1 class="heading mb-3">Falha ao realizar pagamento com <span class="text-primary">PIX.</span></h1>
              <p class="para-desc text-muted">
                  A Sua consulta foi cancelada pois ocorreu um erro ao realizar o pagamento via Pix
              </p>
                <div class="mt-4">
                    <router-link to="/" class="btn btn-primary mt-2 me-2">
                        <i class="uil uil-arrow-right"></i>
                        Agendar novamente
                    </router-link>
                </div>

              <div class="mt-3">
                <router-link to="/schedules" class="btn btn-primary mt-2 me-2">
                  Acessar minha conta
                </router-link>
              </div>

            </div>
          </div>

          <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="text-md-end text-center ms-lg-4">
              <img src="../../assets/images/app-ubbimed.png" class="img-fluid" alt="app Ubbimed"
                   style="transform: scaleX(-1)">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'PixFail',
}
</script>

<style scoped>

</style>
