<template>
  <section class="section bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-md-3">
        <div class="card shadow rounded border-0 mb-3">

          <div v-if="statePage === 'init' || statePage === 'loading'">
            <div class="card shadow rounded border-0 mb-3">
              <div class="row">
                <div class="text-center">
                  <div class="card-body">
                    <div class="content">
                      <img src="@/assets/images/loading.gif" :class="isMobile() ? 'w-75' : 'w-25'" alt="Carregando">
                      <p class="h3">Carrengando informações</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="statePage === 'error'">
            <div class="card shadow rounded border-0 mt-3 mb-3 text-center">
              <div class="row">
                <div class="text-center">
                  <div class="card-body">
                    <div class="content">
                      <img src="@/assets/images/animation_error.gif" :class="isMobile() ? 'w-75' : 'w-25'" alt="Error">
                      <p class="h3 text-muted">Opss... não foi possível carregar os profissionais</p>
                      <button onclick="window.location.reload()" class="btn btn-primary">Tentar novamente</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>

            <div class="row" v-if="professional">
              <div class="col-md-5 mb-6 mt-4">

                <div class="row">
                  <div class="col-md-4">
                    <div class="text-center mt-lg-5">
                      <img :src="url(professional.photo)" v-if="professional.photo"
                           class="avatar avatar-md-md shadow-md rounded-circle" :alt="professional.prof_name">
                      <img src="../../../assets/images/no-photo.png" v-else
                           class="avatar avatar-md-md shadow-md rounded-circle" :alt="professional.prof_name">
                    </div>
                  </div>
                  <div class="col-md-8">
                    <p>
                      <span class="text-primary text-lg-start text-center">
                        <b>{{professional.prof_name}}</b>
                      </span> <br>
                      <b>{{professional.kind_acronym}}</b> {{professional.prof_council}}
                    </p>
                    <div>
                      <label class="form-label" for="specId">Especialidades</label>
                      <select class="form-control" v-model="specId" id="specId" @change="loadAvailabilityProfessional">
                        <option v-for="specialty in specialties" :value="specialty.id_spec">
                          {{ specialty.spec_name }}
                        </option>
                      </select>
                    </div>

                    <div class="row mt-4">
                      <div class="col-6 text-center">
                        <div class="d-grid gap-2">
                          <button type="button" @click="toggleScheduleOnline(true)" :class="scheduleOnline ? 'btn-primary' : 'btn-outline-primary'"
                                  class="btn btn-sm fs-6">
                            Agendar <br>Teleconsulta
                          </button>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-grid gap-2">
                          <button type="button" @click="toggleScheduleOnline(false)" :class="scheduleOnline ? 'btn-outline-primary' : 'btn-primary'"
                                  class="btn btn-sm fs-6">
                            Agendar <br>Presencial
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-12 ms-lg-5">
                    <address v-if="professional.place_address && professional.show_address">
                      <b>Endereço:</b> <br>
                      <span>
                        {{professional.place_address}}, {{professional.place_addr_numer}},
                          {{professional.place_neighborhood}}, {{professional.city_name}}/{{professional.state_initials}}
                      </span>
                    </address>

                    <div v-if="professional.show_phone">
                      <p v-if="professional.prof_phone || professional.place_phone">
                        <b>Telefone:</b> <br>
                        <a :href="'tel:' + professional.prof_phone">
                          <span>
                            {{professional.prof_phone}}
                          </span>
                        </a>
                        <a class="ms-2" :href="'tel:' + professional.place_phone">
                          <span
                                v-if="professional.prof_phone != professional.place_phone">
                            {{professional.place_phone}}
                          </span>
                        </a>
                      </p>
                    </div>

                    <p>
                      <b>Faixa etária:</b> <br>
                      {{professional.patient_age_desc}}
                    </p>

                  </div>
                </div>

              </div>

              <div class="col-md-7 mb-6 mt-4">
                <div class="table-responsive mt-3 pe-4">
                  <table class="table table-sm table-borderless">
                    <tbody>

                    <tr>
                      <th v-if="!hideBtnPreviousDatesProf" scope="col" class="text-start" @click="previousPageProf();"
                          style="cursor: pointer;">
                        <i class="fas fa-chevron-left"></i>
                      </th>
                      <th v-else></th>

                      <th scope="col" class="text-center" v-for="availabilityDate in professional?.availability_dates">
                        <small>
                          {{ availabilityDate.name_available_date_patient }} <br>
                          <b>
                            {{
                              date(availabilityDate.available_date_patient).format('DD')
                            }}/{{ availabilityDate.name_month_available_date_patient }}
                          </b>
                        </small>
                      </th>

                      <th v-if="hideBtnNextDatesProf"></th>
                      <th v-else-if="professional?.availability_dates?.length > 0"
                          scope="col" class="text-end" @click="nextPageProf();"
                          style="cursor: pointer;">
                        <i class="fas fa-chevron-right"></i>
                      </th>
                    </tr>

                    <tr v-if="professional.availability_dates?.length <= 0">
                      <td colspan="12">
                        <img :src="require('@/assets/images/save_to_bookmarks.svg')"
                             :style="{width: isMobile() ? '25%' : '12%'}"
                             alt="Doctor" class="rounded img-fluid mx-auto d-block">
                        <br>
                        <div  class="text-center">
                          <p class="text-muted para-desc mx-auto">
                              <span class="text-dark">
                                Nenhum horário disponível.
                              </span>
                            <br>
                          </p>
                          <button class="btn btn-primary"
                                  data-bs-target="#modal-waiting-list" data-bs-toggle="modal">
                            Solicitar encaixe
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr v-else v-for="indexRow in (quantityRows = getQuantityRows())"
                        class="text-center"
                        :class="quantityRows > quantityRowsAllowed && indexRow > quantityRowsAllowed && !expandedTimesProf ? 'd-none' : ''">
                      <th></th>
                      <td v-for="availabilityDate in professional.availability_dates">
                        <button @click="selectAvailability(availabilityDate.availability_times[indexRow-1], professional)"
                                class="btn btn-sm btn-pills btn-outline-primary"
                                v-if="availabilityDate.availability_times[indexRow-1]">
                          <b>{{ availabilityDate.availability_times[indexRow - 1].available_time_patient }}</b>
                        </button>
                      </td>
                      <th></th>
                    </tr>

                    </tbody>
                  </table>
                </div>
                <div class="text-center" v-if="quantityRows > 4">
                  <button v-if="expandedTimesProf" class="btn btn-link"
                          @click="toggleExpandedTimesProf()">
                    Mostrar menos
                    <i class="fas fa-angle-double-up"></i>
                  </button>
                  <button v-else-if="professional.availability_dates?.length > 0"
                          @click="toggleExpandedTimesProf()" class="btn btn-link">
                    Mostrar todos
                    <i class="fas fa-angle-double-down"></i>
                  </button>
                </div>
              </div>
            </div>

            <div v-else>
              <p class="m-5 text-center"> Nenhuma informação encontrada.</p>
            </div>
          </div>
        </div>
        <br>
        <div class="row mt-4" v-if="professionalsRecommended.length > 0">
          <br>
          <hr>
          <div class="rounded bg-primary bg-gradient p-lg-2 p-2" >
            <div class="section-title text-md-start text-center">
              <p class="text-white title-dark">
                <span class="h5">Não encontrou o horário que desejava?</span> <br>
                <span class="text-white">Veja outros profissionais parceiros para essa especialidade.</span>
              </p>
            </div>
          </div>

          <div class="card shadow rounded border-0 mb-3 mt-4" :class="isMobile() ? '' : 'pricing-rates business-rate'"
               v-for="profRecommended in professionalsRecommended"
               :id="'prof-recommended-'+profRecommended.id_prof + '-' + profRecommended.id_place">

            <div class="row">
              <div class="col-md-4">
                <div class="card-body">
                  <div class="content">
                    <div class="row">
                      <div class="col-4 text-center">
                        <img :src="url(profRecommended.photo)" v-if="profRecommended.photo"
                             class="avatar avatar-md-md shadow-md rounded-circle" :alt="profRecommended.prof_name">
                        <img src="../../../assets/images/no-photo.png" v-else
                             class="avatar avatar-md-md shadow-md rounded-circle" :alt="profRecommended.prof_name">
                      </div>
                      <div class="col-8">
                        <router-link class="h5"
                                     :to="`/professional/${profRecommended.id_prof}?city=${profRecommended.id_city}&place=${profRecommended.id_place}`">
                          {{profRecommended.prof_name}}
                        </router-link>

                        <div class="">
                          <p class="text-muted">
                            <b>
                              {{specialty.spec_name.toUpperCase()}}
                              <a href="#" class="mt-1" v-if="profRecommended.specialties_count > 1"
                                 data-bs-toggle="modal" data-bs-target="#ModalSpecialties"
                                 title="Ver todas"
                                 @click.prevent="getAllSpecialtiesFromProfessional(profRecommended)">
                                    <span class="badge bg-primary">
                                      <i  class="fas fa-angle-down"></i>
                                    </span>
                              </a>
                            </b>
                            <br>
                            <b>{{profRecommended.kind_acronym}}</b> {{profRecommended.prof_council}} <br><br>
                          </p>
                        </div>
                      </div>
                    </div>

                    <p>
                      <b>Endereço:</b> <br>
                      <span v-if="profRecommended.show_address && profRecommended.place_address">
                            {{profRecommended.place_address}}, {{profRecommended.place_addr_numer}}<br>
                            {{profRecommended.place_neighborhood}},
                          </span>
                      <span v-if="profRecommended.city_name && profRecommended.state_initials">
                            {{profRecommended.city_name}}/{{profRecommended.state_initials}}
                          </span>
                    </p>

                    <p v-if="profRecommended.min_patient_age != null || profRecommended.max_patient_age != null">
                      <b>Faixa etária:</b> <br>
                      {{profRecommended.patient_age_desc}}
                    </p>

                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <Times
                  :id_prof="profRecommended.id_prof"
                  :id_place="profRecommended.id_place"
                  :id_spec="profRecommended.id_spec"
                  :id_city="profRecommended.sched_online ? null : profRecommended.id_city"
                  :id_insurance="profRecommended.id_insurance"
                  :id_paymeth="profRecommended.id_paymeth"
                  :id_sched_father="profRecommended.id_sched"
                  :professional="profRecommended"
                  @onClickDate="selectAvailability($event, profRecommended)"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>

  <SpecialtiesModal :specialties="specialtiesFromProfessional" />
  <WaitingListModal :professional="professional" :cityId="cityId" :specId="specId" />
</template>

<script>
import http from "../../../helpers/http";
import date from "../../../helpers/date";
import Device from "../../../helpers/device";
import str from "../../../helpers/str";
import WaitingListModal from "../WaitingListModal.vue";
import Times from "../../components/Times.vue";
import SpecialtiesModal from "../SpecialtiesModal.vue";

export default {
  name: "ProfessionalIndex",
  components: {SpecialtiesModal, Times, WaitingListModal},
  data() {
    return {
      statePage: 'init', // init, loading, error, loaded
      professionalsRecommended: [],
      specialtiesFromProfessional: [],
      professional: null,
      professionalid: null,
      cityId: null,
      placeId: null,
      specId: null,
      quantityRows: null,
      specialties: [],
      expandedTimesProf: false,
      hideBtnPreviousDatesProf: true,
      hideBtnNextDatesProf: false,
      startEndDays: this.isMobile() ? 3 : 7,
      quantityRowsAllowed: this.isMobile() ? 4 : 8,
      scheduleOnline: false,
    }
  },
  mounted() {
    this.statePage = 'loading'
    this.professionalid = this.$route.params.professionalId;
    this.cityId = this.$route.query.city;
    this.placeId = this.$route.query.place;
    this.getSpecialties().then(() => {
      this.specId = this.$route.query.specialty ?? this.specialties[0].id_spec;
      this.findProfessional();
    }).then(() => {
      this.statePage = 'loaded'
    }).catch(() => {
      this.statePage = 'error';
    });
  },
  computed: {
    specialty() {
      return this.specialties.find(spec => spec.id_spec === this.specId);
    }
  },
  methods: {
    date: date,
      url(path) {
          return str.url(path)
      },
    isMobile() {
      return Device.isMobile();
    },
    toggleScheduleOnline(isOnline) {
      this.scheduleOnline = isOnline;
      this.loadAvailabilityProfessional();
    },
    getProfessionalsRecommended() {
      http.get('/schedule/professionals/recommendations', {
        params: {
          id_city: this.scheduleOnline ? null : this.cityId,
          id_spec: this.specId,
          id_paymeth: null,
          id_insurance: null,
          id_place: null,
          with_availability: true,
          start_end_days: this.startEndDays,
          id_place_not: null,
          id_profs_not: [this.professionalid],
        }
      }).then((response) => {
        this.professionalsRecommended = response.data;
      });
    },
    findProfessional() {
      let params = {
        start_end_days: this.startEndDays,
        id_prof: this.professionalid,
        id_place: this.placeId,
        id_spec: this.specId,
        id_city: this.scheduleOnline ? null : this.cityId,
      };

      return http.get(`/schedule/professionals/${this.professionalid}`, {params: params}).then((response) => {
        this.professional = response.data;
        if (this.professional.default_release_time_for === 'O') {
          this.scheduleOnline = true;
        } else if (this.professional.default_release_time_for === 'F') {
          this.scheduleOnline = false;
        }

        this.loadAvailabilityProfessional();
        this.getProfessionalsRecommended();
      });
    },
    loadAvailabilityProfessional() {
      let params = {
        with_availability: true,
        start_end_days: this.startEndDays,
        id_prof: this.professionalid,
        id_place: this.placeId,
        id_city: this.scheduleOnline ? null : this.cityId,
        id_spec: this.specId,
      };

      http.get(`/schedule/professionals/${this.professionalid}`, {params: params}).then((response) => {
        this.professional.availability_dates = response.data.availability_dates;
      });

      this.getProfessionalsRecommended();
    },
    toggleExpandedTimesProf() {
      this.expandedTimesProf = !this.expandedTimesProf;
    },
    getQuantityRows() {
      let quantityRows = 0;
      this.professional.availability_dates?.forEach(function (availabilityDate) {
        let quantityTimes = availabilityDate.availability_times.length
        if (quantityTimes > quantityRows)
          quantityRows = quantityTimes;
      });

      return quantityRows;
    },
    findCity() {
      return http.get(`/cities/${this.cityId}`);
    },
    async selectAvailability(availabilityTime, professional) {
      this.$store.commit('toSchedule/setScheduleType', 'PROFESSIONAL');

      if (!this.scheduleOnline && this.cityId) {
        let responseCity = await this.findCity();
        this.$store.commit('toSchedule/selectCity', responseCity.data);
      } else {
        this.$store.commit('toSchedule/selectCity', null);
      }

      let specialty = this.specialties.find(spec => spec.id_spec === this.specId);
      this.$store.commit('toSchedule/selectSpecialty', specialty);

      this.$store.commit('toSchedule/selectAvailability', {professional, availabilityTime});

      this.$router.push(`/professional/${professional.id_prof}/payment-methods`);
    },
    nextPageProf() {
      let professional = this.professional;
      let availabilityDates = professional.availability_dates;

      let startAvailability = availabilityDates[1];
      if (!startAvailability) {
        this.hideBtnNextDatesProf = true;
        return;
      }

      let startDate = startAvailability.available_date_patient;
      http.get('/schedule/availabilities/dates-times', {
        params: {
          id_place: professional.id_place,
          id_prof: professional.id_prof,
          id_spec: this.specId,
          id_city: this.scheduleOnline ? null : this.cityId,
          start_date: startDate,
          start_end_days: this.startEndDays,
        }
      }).then((response) => {
        let availability = response.data;
        if (date(startDate) <= date())
          this.hideBtnPreviousDatesProf = true;

        this.hideBtnNextDatesProf = false
        this.professional.availability_dates = availability;
      });
    },
    previousPageProf() {
      let professional = this.professional;
      let availabilityDates = professional.availability_dates;
      let startAvailability = availabilityDates[0];

      if (this.currentStartDate) {
        this.currentStartDate = date(this.currentStartDate).add(-1, 'day').format('YYYY-MM-DD');
      } else {
        this.currentStartDate = date(startAvailability.available_date_patient).add(-1, 'day').format('YYYY-MM-DD');
      }

      http.get('/schedule/availabilities/dates-times', {
        params: {
          id_place: professional.id_place,
          id_prof: professional.id_prof,
          id_spec: this.specId,
          id_city: this.scheduleOnline ? null : this.cityId,
          start_date: this.currentStartDate,
          start_end_days: this.startEndDays,
        }
      }).then((response) => {
        let availability = response.data;
        let firstDate = availability[0].available_date_patient;
        if (date(firstDate) <= date())
          this.hideBtnPreviousDatesProf = true;

        this.hideBtnNextDatesProf = false
        this.professional.availability_dates = availability;
      });
    },
    getSpecialties() {
      return http.get(`/professionals/${this.professionalid}/specialties`).then((specialties) => this.specialties = specialties.data);
    },
    getAllSpecialtiesFromProfessional(professional) {
      http.get(`/professionals/${professional.id_prof}/specialties`).then((specialties) => this.specialtiesFromProfessional = specialties.data);
    },
  }
}
</script>

<style scoped>

</style>