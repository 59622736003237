<template>
    <div>
        <div class="col-md-12 mb-6" v-if="professional?.availability_dates">
            <div class="table-responsive mt-3 pe-4">
                <table class="table table-sm table-borderless">
                    <tbody>
                    <tr>
                        <th v-if="!hideBtnPreviousDatesProf" scope="col" class="text-start" @click="previousPageProf();"
                            style="cursor: pointer;">
                            <i class="fas fa-chevron-left"></i>
                        </th>
                        <th v-else></th>

                        <th scope="col" class="text-center" v-for="availabilityDate in professional?.availability_dates">
                            <small>
                                {{ availabilityDate.name_available_date_patient }} <br>
                                <b>
                                    {{
                                        date(availabilityDate.available_date_patient).format('DD')
                                    }}/{{ availabilityDate.name_month_available_date }}
                                </b>
                            </small>
                        </th>

                        <th v-if="hideBtnNextDatesProf"></th>
                        <th v-else
                            scope="col" class="text-end" @click="nextPageProf();"
                            style="cursor: pointer;">
                            <i class="fas fa-chevron-right"></i>
                        </th>
                    </tr>

                    <tr v-if="professional?.availability_dates <= 0">
                        <td colspan="12">
                            <img :src="require('@/assets/images/save_to_bookmarks.svg')"
                                 alt="Doctor" class="rounded img-fluid mx-auto d-block w-25">
                            <br>
                            <p class="text-center text-muted para-desc mx-auto">
                            <span class="text-dark">
                              Nenhum horário disponível.
                            </span>
                                <br>
                            </p>
                        </td>
                    </tr>

                    <tr v-else v-for="indexRow in (quantityRows = getQuantityRows(professional?.availability_dates))"
                        class="text-center"
                        :class="quantityRows > 4 && indexRow > 4 && !expandedTimesProf ? 'd-none' : ''">
                        <th></th>
                        <td v-for="availabilityDate in professional.availability_dates">
                            <button @click="onClickDate(availabilityDate.availability_times[indexRow-1])"
                                    class="btn btn-sm btn-pills btn-outline-primary"
                                    v-if="availabilityDate.availability_times[indexRow-1]">
                                <b>{{ availabilityDate.availability_times[indexRow - 1].available_time_patient }}</b>
                            </button>
                        </td>
                        <th></th>
                    </tr>

                    </tbody>
                </table>
            </div>
            <div class="text-center" v-if="quantityRows > 4">
                <button v-if="expandedTimesProf" class="btn btn-link"
                        @click="toggleExpandedTimesProf()">
                    Mostrar menos
                    <i class="fas fa-angle-double-up"></i>
                </button>
                <button v-else-if="professional.availability_dates.length > 0"
                        @click="toggleExpandedTimesProf()" class="btn btn-link">
                    Mostrar todos
                    <i class="fas fa-angle-double-down"></i>
                </button>
            </div>
        </div>
        <div v-else>
            <p class="text-center">
                <img :src="require('@/assets/images/not_found.svg')"
                     alt="Foto" class="w-25 align-self-center"/>
                Nenhum horário disponível.
            </p>
        </div>
    </div>
</template>

<script>
import http from "@/helpers/http";
import date from "@/helpers/date";
import Device from "../../helpers/device";

export default {
    name: "Times",
    props: {
        id_spec: Number,
        id_city: Number,
        id_paymeth: Number,
        id_insurance: Number,
        id_place: Number,
        id_prof: Number,
        id_sched_father: Number,
        professional: Object,
    },
    data() {
        return {
            quantityRows: null,
            hideBtnNextDatesProf: false,
            hideBtnPreviousDatesProf: true,
            expandedTimesProf: false,
            startEndDays: this.isMobile() ? 3 : 7,
            currentStartDate: null,
        }
    },
    methods: {
        date: date,
        isMobile() {
            return Device.isMobile();
        },
        toggleExpandedTimesProf() {
            this.expandedTimesProf = !this.expandedTimesProf;
        },
        getQuantityRows() {
            let quantityRows = 0;
            this.professional?.availability_dates?.forEach(function (availabilityDate) {
                let quantityTimes = availabilityDate.availability_times.length
                if (quantityTimes > quantityRows)
                    quantityRows = quantityTimes;
            });

            return quantityRows;
        },
        previousPageProf() {
            let professional = this.professional;
            let availabilityDates = professional.availability_dates;
            let startAvailability = availabilityDates[0];

            if (this.currentStartDate) {
                this.currentStartDate = date(this.currentStartDate).add(-1, 'day').format('YYYY-MM-DD');
            } else {
                this.currentStartDate = date(startAvailability.available_date_patient).add(-1, 'day').format('YYYY-MM-DD');
            }

            http.get('/schedule/availabilities/dates-times', {
                params: {
                    id_place: this.id_place,
                    id_prof: this.id_prof,
                    id_spec: this.id_spec,
                    id_city: this.id_city,
                    id_paymeth: this.id_paymeth,
                    id_insurance: this.id_insurance,
                    start_date: this.currentStartDate,
                    start_end_days: this.startEndDays,
                }
            }).then((response) => {
                let availability = response.data;
                if (date(this.currentStartDate) <= date())
                    this.hideBtnPreviousDatesProf = true;

                this.hideBtnNextDatesProf = false
                this.professional.availability_dates = availability;
            });
        },
        nextPageProf() {
            let professional = this.professional;
            let availabilityDates = professional.availability_dates;

            let startAvailability = availabilityDates[1];
            if (!startAvailability) {
                this.hideBtnNextDatesProf = true;
                return;
            }

            let startDate = startAvailability.available_date_patient;
            http.get('/schedule/availabilities/dates-times', {
                params: {
                    id_place: this.id_place,
                    id_prof: this.id_prof,
                    id_spec: this.id_spec,
                    id_city: this.id_city,
                    id_paymeth: this.id_paymeth,
                    id_insurance: this.id_insurance,
                    start_date: startDate,
                    start_end_days: this.startEndDays,
                }
            }).then((response) => {
                let availability = response.data;
                this.hideBtnPreviousDatesProf = false;
                if (availability.length < this.startEndDays) {
                    this.hideBtnNextDatesProf = true
                }

                this.professional.availability_dates = availability;
            });
        },
        onClickDate(availabilityTime) {
            this.$emit('onClickDate', availabilityTime)
        }
    }
}
</script>

<style scoped>

</style>