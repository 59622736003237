import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
window.bootstrap = require('bootstrap');
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './websocket';
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(store).use(router);

app.use(VueGtag, {
    appName: 'My application',
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-YCYEM5LVYZ" }
}, router);

app.mount('#app')