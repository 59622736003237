<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">

        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col col-lg-9 col-12 order-1 order-sm-2 order-md-2">

            <p class="h4">
                <b>Escolha {{isExam ? 'o exame' : 'a especialidade'}}</b>
            </p>

            <div class="mb-4">
                <div class="form-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-search fea icon-sm icons">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input id="specialties" class="form-control ps-5" placeholder="Pesquisar ..." v-model="search">
                </div>
            </div>

          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" @click="selectSpecialty(specialty)"
               style="cursor: pointer;" v-for="specialty in specialties">
            <div class="m-3">
              <b class="h6">{{specialty.spec_name}}</b>
              <i class="ms-1 fas fa-1x fa-arrow-circle-right text-primary"></i>
            </div>
          </div>

          <NoResult v-if="specialties.length <= 0"/>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MenuAction from './MenuAction.vue'
import NoResult from './NoResult.vue'

export default {
  name: 'Specialties',
  components: {
    MenuAction,
    NoResult,
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    specialties() {
      let specialties = this.$store.state.toSchedule.specialties;
      if (this.search === '')
        return specialties;

      return specialties.filter(specialty => {
        let strFilter = JSON.stringify(specialty);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
      isExam() {
        return this.$store.state.toSchedule.attendanceType === 'EXAM';
      }
  },
  mounted() {
    this.$store.dispatch('toSchedule/getSpecialties');
  },
  methods: {
    selectSpecialty(specialty) {
      this.$gtag.event('Click', {
          'event_category': 'Specialties',
          'event_label': specialty.spec_name,
          'value': specialty.id_spec,
      });

      this.$store.commit('toSchedule/selectSpecialty', specialty);
      this.$router.push('/payment-methods')
    },
  }
}
</script>

<style scoped>

</style>
